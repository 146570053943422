import gsap from "gsap";

class AnimationHandler{

	/**
	 * [constructor description]
	 * @param  {DOMElement} tarElement [description]
	 * @return 
	 */
	constructor(){

		//////////////////
		///GET ALL SECTONS
		
		this.sections = document.querySelectorAll("section[animtype], footer");

		//////////////
		/// EVENTS
		//////////////

		this.last_known_scroll_position = 0;
		this.ticking = false;
		window.addEventListener( "scroll", (e) => {
			this.last_known_scroll_position = window.scrollY;

			if (!this.ticking) {
				window.requestAnimationFrame(function() {
					this.evaluate();
					this.ticking = false;
				}.bind(this));

				this.ticking = true;
			}
		});
		//
		window.dispatchEvent(new CustomEvent('scroll'));
	}

	evaluate(){
		this.sections.forEach((item)=>{
			if(this.isVisible(item) && !item.getAttribute("initiated")){
				switch(item.getAttribute("animtype")){
					case "cards":
					this.cards(item);
					break;
					case "studio":
					this.studio(item);
					break;
					case "contact":
					this.contact(item);
					break;

				}
				item.setAttribute("initiated", "done")
			}
		});
	}

	isVisible(el){
		let clientrect = el.getBoundingClientRect();
		let viewportRect = {top:0, bottom: window.innerHeight};
		let verticalVisible = Math.max(0, Math.min(viewportRect.bottom, clientrect.bottom) - Math.max(viewportRect.top, clientrect.top));
		if(verticalVisible >= clientrect.height/4){
			return true;
		}else{
			return false;
		}
	}

	////////////////
	/// INTROS
	////////////////
	
	cards(item){
		var tl = gsap.timeline();
		tl.staggerFrom(item.querySelectorAll("h2"), 0.7, {y:20, autoAlpha:0}, 0);
		tl.staggerFrom(item.querySelectorAll(".card, article"), 0.7, {y:20, autoAlpha:0}, 0.3, 0.1);
		tl.play();
	}

	studio(item){
		var tl = gsap.timeline();
		tl.staggerFrom(item.querySelectorAll("h2"), 0.7, {y:20, autoAlpha:0}, 0);
		tl.staggerFrom(item.querySelectorAll("p"), 0.7, {y:20, autoAlpha:0}, 0.4);
		tl.staggerFrom(item.querySelectorAll(".btn-wrapper"), 0.7, {y:20, autoAlpha:0}, 0.4);
	}
	contact(item){
		var tl = gsap.timeline();
		tl.staggerFrom(item.querySelectorAll("h2"), 0.7, {y:20, autoAlpha:0}, 0);
		tl.staggerFrom(item.querySelectorAll("li"), 0.7, {x:-20, opacity:0}, 0.1, 0)
		tl.staggerFrom(item.querySelectorAll("img, label, input, p, textarea, a, button"), 0.4, {y:20, opacity:0}, 0.1, 0.2)
	}
}
export default AnimationHandler;