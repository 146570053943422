// router vainilla

class PseudoRouter{

    /**
     * Builds the router
     * @param {Object} options 
     */
    constructor(options){

        //parse options
        this.settings = {
            mode: "history",
            root: "/"
        }
        this.settings = Object.assign(this.settings, options);

        //set routes bypassing listeners
        this._omitCheck = false;

        //create routes array
        this.routes = [];

        //return self reference
        return this;
    }

    /**
     * Adds a route to listen
     * @param {String} path 
     * @param {Function} handler 
     */
    route(path, handler){
        this.routes.push({
            path: path,
            handler: handler
        })
        return this;
    }

    /**
     * Add event listeners
     */
    startListening(){
        if(this.settings.mode == "history"){
            window.addEventListener('popstate', this.check.bind(this));
        }else{
            window.addEventListener('hashchange', this.check.bind(this));
        }
        return this;
    }

    /**
     * Navigate to target route
     * @param {String} path the url.
     * @param {Boolean}  omitCheck omit next check.
     * @param {Boolean}  omitHistoryPush omit history push.
     */
    navigateTo(path, omitCheck, omitHistoryPush){
        if(this.settings.mode == "history"){
            this._omitCheck = omitCheck;
            if(omitHistoryPush){
                history.replaceState(undefined, undefined, path)
            }else{
                window.history.pushState({}, null, path);
            }
        }else{
            if(this._getHashPath() == path && !omitCheck){
                this.check();
            }else{
                this._omitCheck = omitCheck;
                if(omitHistoryPush && history){
                    history.replaceState(undefined, undefined, "#"+path);
                }else{
                    window.location.hash = path;
                }
            }
        }
        if(this.settings.afterNav && !omitCheck) this.settings.afterNav(); 
    }

    /**
     * Performs a check to find matching route for current history state
     */
    check(){
        if(this._omitCheck){
            this._omitCheck != this._omitCheck;
            return;
        }

        let path = "";
        if(this.settings.mode == "history"){
            path = this._getHistoryPath();
        }else{
            path = this._getHashPath();
        }
        let route = this.routes.find(e => e.path == path);
        if(route) route.handler();
    }

    /**
     * Get the current path from history
     */
   _getHistoryPath() {
        var path = decodeURI(window.location.pathname);
        if (this.settings.root !== "/") {
            path = path.replace(this.root, "");
        }
        return this._trimPath(path);
    };

    /**
     * Get the current path from hash
     */
    _getHashPath () {
        var path = window.location.hash.substr(1).replace(/(\?.*)$/, "");
        return this._trimPath(path);
    };

    /**
     * Trims the path removing extra slashes
     * @param {String} path 
     */
    _trimPath(path){
        return path.toString().replace(/\/$/, "").replace(/^\//, "");
    }
}



/**
 * Plugin to monitor scroll position
 */
class SectionMonitor{
    constructor(pseudoRouter, omitChecks, debug = false){
        this.pseudoRouter = pseudoRouter;
        this.omitChecks = omitChecks;
        this.debugMode = debug;

        this.scrollpos = 0;
        this.lasCrollPos = 0;
        this.ticking = false;
        this.elems = [];

        this.scrollCheckThereshold = 140;
    }

    /**
     * Add an element that will trigger a navigarion on the router
     * @param {HTMLElement} elem element to monitor on scroll
     * @param {String} route corresponding route added to the pseudorouter
     * @param {HTMLElement} navelement a navigation element (div, button, href) to add a toggle class
     * @param {String} activeclassname the classname to add. If left empty, default is "active"
     */
    add(elem, route, navelement, activeclassname){
        this.elems.push({elem:elem, route:route, navelement:navelement, activeclassname:activeclassname, occPercent:0});

        //find all links matching the pattern
        document.querySelectorAll("a[href='/#"+route+"'], a[href='#"+route+"']").forEach(item => {
            
            if(item.pathname == window.location.pathname){
                item.onclick= function(e){
                    e.preventDefault();
                    if(this.pseudoRouter.settings.mode == "history"){
                        this.pseudoRouter.navigateTo(a.pathname);
                    }else{
                        this.pseudoRouter.navigateTo(this._getHashPath(item.hash));
                    }
                    return false;
                }.bind(this);
            }
        });

        //add click events
        /*if(navelement){
            var a = document.createElement('a');
            a.href = navelement.getAttribute("href");
            if(a.pathname == window.location.pathname){
                navelement.onclick= function(e){
                    e.preventDefault();
                    if(this.pseudoRouter.settings.mode == "history"){
                        this.pseudoRouter.navigateTo(a.pathname);
                    }else{
                        this.pseudoRouter.navigateTo(this._getHashPath(a.hash));
                    }
                    return false;
                }.bind(this);
            }
        }*/
    }

    /**
     * Add events for scroll and resize
     */
    addEvents(){
        window.addEventListener("resize", this._updatePositions.bind(this));
        window.addEventListener("scroll", this._optimizedScrollListener.bind(this));
        this._updatePositions();

        //attempt again when font is loaded
        if(document.fonts){
            if(document.fonts.ready){
                document.fonts.ready.then(()=> {
                    this._updatePositions();
                    return;
                });
            }
        }
        //otherwise use a timeout
        setTimeout(() => {
            this._updatePositions();
        }, 1000);
    }

    /**
     * Optimized (scroll listener, triggers a check)
     */
    _optimizedScrollListener(){
        this.scrollpos = (window.scrollY | window.pageYOffset);
        if (!this.ticking) {
            window.requestAnimationFrame(function() {
                if(Math.abs(this.lasCrollPos - this.scrollpos) > this.scrollCheckThereshold){
                    this.check();
                    this.lasCrollPos = this.scrollpos;
                }
                this.ticking = false;
            }.bind(this));
        }
        this.ticking = true;
    }

    /**
     * Update positions (resize listener)
     */
    _updatePositions(){
        this.elems.forEach((item, index) => {
            if(item.elem){
                let rect = item.elem.getBoundingClientRect();
                item.start = rect.top + (window.scrollY | window.pageYOffset);
                item.end = rect.bottom + (window.scrollY | window.pageYOffset);
                item.height = rect.height;
                if(this.debugMode) this._addDebugMarker(item, index);
            }
        })
    }

    _addDebugMarker(elem, i){
        let el = document.querySelector("div[indexsec='"+i+"']");
        if(!el) el = document.createElement("div");
        el.setAttribute("style", "background:#"+Math.floor(Math.random()*16777215-1000).toString(16)+";width:100vw;opacity:0.2;pointer-events:none;height:"+elem.height+"px;position:absolute;top:"+elem.start+"px;left:0px;");
        el.setAttribute("start", elem.start);
        el.setAttribute("end", elem.end);
        el.setAttribute("height", elem.height);
        el.setAttribute("indexsec", i);
        document.getElementsByTagName("body")[0].append(el);
    }

    /**
     * Get the hash route without querystring
     * @param {String} hash 
     */
    _getHashPath (hash) {
        var path = hash.substr(1).replace(/(\?.*)$/, "");
        return this._trimPath(path);
    };

    /**
     * Trims the path removing extra slashes
     * @param {String} path 
     */
    _trimPath(path){
        return path.toString().replace(/\/$/, "").replace(/^\//, "");
    }

    /**
     * Check all elements based on scroll pos (scroll)
     */
    check(){
        let viewportRect = {top:0, bottom: window.innerHeight};
        this.visibleSections = [];
        this.elems.forEach(el => {
            if(el.elem){
                let clientrect = el.elem.getBoundingClientRect();
                el.visible = Math.max(0, Math.min(viewportRect.bottom, clientrect.bottom) - Math.max(viewportRect.top, clientrect.top));
                if(el.visible) this.visibleSections.push(el);
                if(el.navelement){
                    let toggleClassName = el.activeclassname ? el.activeclassname : "active";
                    el.navelement.classList.remove(toggleClassName);
                }
            }
        });
        //store the visible sections    
        this.visibleSections.sort((a,b) => b.visible-a.visible);
        //update the active one
        if(this.visibleSections[0]){
            if(this.visibleSections[0].navelement) this.visibleSections[0].navelement.classList.add(this.visibleSections[0].elem.activeclassname ? this.visibleSections[0].elem.activeclassname : "active")
            //this.pseudoRouter.navigateTo(this.visibleSections[0].route, this.omitChecks, true);
        }
    }

}

export {PseudoRouter, SectionMonitor};