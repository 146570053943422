import "../scss/global.scss";
import Menu from "./components/Menu";
import AnimationHandler from "./components/AnimationHandler.js";
import objectFitImages from 'object-fit-images';
import { gsap, ScrollToPlugin, Power2} from "gsap/all";
import {PseudoRouter, SectionMonitor} from './components/PseudoRouter';
import Polyfills from './helpers/polyfills';
import axios from 'axios';

//add gsao scrolloplugin
gsap.registerPlugin(ScrollToPlugin);

window.addEventListener( "load", (event) => {

  //vimeo controller
  window.menu = new Menu();

  //animation handler
  let animHanlder = new AnimationHandler();

  //intro animation
  gsap.to("#loadingOverlay", {x:"100%", delay:1.5})

  //object fit cover polyfill
  objectFitImages();

  //pseudo router
  var router = new PseudoRouter({mode:"hash", afterNav:onAfterNavto});
  router.route("servicios", function(){
    navToElem("serviciossec");
  })
  router.route("estudio", function(){
    navToElem("estudiosec");
  })
  router.route("equipo", function(){
    navToElem("equiposec");
  })
  router.route("contacto", function(){
    navToElem("contactosec");
  })
  router.route("blog", function(){
    navToElem("blogsec");
  })

  router.startListening();

  //scroll handling
  var secMonitor = new SectionMonitor(router, true);
  secMonitor.add(document.getElementById("serviciossec"), "servicios", document.getElementById("serviciosnav"));
  secMonitor.add(document.getElementById("estudiosec"), "estudio", document.getElementById("estudionav"));
  secMonitor.add(document.getElementById("equiposec"), "equipo", document.getElementById("equiponav"));
  secMonitor.add(document.getElementById("contactosec"), "contacto", document.getElementById("contactonav"));
  secMonitor.add(document.getElementById("blogsec"), "blog", document.getElementById("blognav"));
  secMonitor.add(document.getElementById("hero"), "inicio");
  secMonitor.add(document.getElementById("articulosec"), "articulo");
  secMonitor.addEvents();

  //handle initial load hash
  let hash = secMonitor._getHashPath(window.location.hash);
  if(hash){
    window.location.hash = "#"+hash+"sec";
    router.navigateTo(hash);
  }

  //exit preload
  gsap.to("#globalPreload", {duration:1, y:"100%", display:"none", ease:Power2.easeIn});
});

function onAfterNavto(){
  window.menu.closeMobile();
}

function navToElem(hash){
  let elem = document.getElementById(hash);
		if(elem){
      let top = getOffsetTop(elem);
      let offset = Math.sign(top - (window.scrollY | window.pageYOffset)) == 1 ? 0 : 80;
			let dist = Math.abs(top - (window.scrollY | window.pageYOffset));
      let time = Math.min(dist/1000, 1.5);
			gsap.to(window, {duration: time, scrollTo: {y: top - offset, autoKill: true }});
			return true;
		}
		return false;
}

function getOffsetTop(elem) {
  let rect = elem.getBoundingClientRect();
  return rect.top + (window.scrollY | window.pageYOffset) ;
}

window.iniFormContacto = function(){
  console.log("init form");
  //form
  let form = document.getElementById("formContacto");
  form.addEventListener("submit", submitForm);
}

function submitForm(evt){
  let button = document.getElementById("formContacto").getElementsByTagName("button")[0];
  //disable btn
  button.setAttribute("disabled", true);
  //execute recaptcha challenge
  grecaptcha.execute();
  //stop
  evt.preventDefault();
  return false;
}

window.recaptchaDone = function(){
  let form = document.getElementById("formContacto");
  let formData = new FormData(form);
  let feedback = form.getElementsByClassName("feedback")[0];

  axios.post('/contacto/enviar', formData)
  .then(function (response) {
    feedback.classList.remove("error");
    feedback.innerHTML = "Gracias. Nos contactaremos a la brevedad.";
    form.querySelectorAll("button")[0].innerHTML = "Enviado";
    form.querySelectorAll("textarea, input").forEach(e => e.setAttribute("disabled", true));
    form.querySelectorAll("button")[0].innerHTML = "Enviando";
  })
  .catch(function (error) {
    let button = form.getElementsByTagName("button")[0];
    button.removeAttribute("disabled");
    feedback.classList.add("error");
    if(error.response.data.msg){
      feedback.innerHTML = error.response.data.msg;
      form.querySelectorAll("button")[0].innerHTML = "Enviar";
    }else{
      feedback.innerHTML = "Se produjo un error inesperado."
      form.querySelectorAll("button")[0].innerHTML = "Enviar";
    }
    
    grecaptcha.reset();
  });
}